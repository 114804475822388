<template>
  <vx-card>
    <div class="flex flex-wrap-reverse items-center">
      <div
        v-permission="'proctoring.create'"
        class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between font-medium text-base text-primary border border-solid border-primary"
        @click="addNew"
      >
        <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-primary">{{ $t('AddNew') }}</span>
      </div>
    </div>

    <Grid
      :service="service"
      :route_name="routeName"
      order_column="updated_at"
      ref="grid"
      route_grid_path="/config/grid"
    >
      <template v-slot:gridThead>
        <vs-th key="id" sort-key="id">ID</vs-th>
        <vs-th key="questionnaire" sort-key="questionnaire_name">{{ $t('Questionnaire') }}</vs-th>
        <vs-th key="situation">{{ $t('stream-de-video') }}</vs-th>
        <vs-th key="situation">{{ $t('situacao') }}</vs-th>
        <vs-th key="created_at" sort-key="created_at">{{ $t('data-de-criacao') }}</vs-th>
      </template>

      <template v-slot:gridActions>
        <vs-th key="actions">{{ $t('Actions') }}</vs-th>
      </template>

      <template v-slot:gridData="props">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
          <vs-td width="6%" key="id">
            {{ tr.id }}
          </vs-td>
          <vs-td width="30%" key="questionnaire">
            {{ !isEmpty(tr.questionnaire) ? tr.questionnaire.name : '---' }}
          </vs-td>
          <vs-td width="15%">
            {{ recordMedium(tr.record_medium) }}
          </vs-td>
          <vs-td width="18%" key="situation">
            <div class="w-full flex flex-row flex-no-wrap items-center" v-if="!isEmpty(tr.questionnaire)">
              <feather-icon
                v-if="tr.status === 'E' | tr.status === 'D'"
                class="py-2"
                v-permission="'proctoring_config.toggle'"
                :icon="statusButtonIcon(tr.status)"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-success"
                @click="toggleStatus(tr)"
                v-tooltip="{
                  content: statusButtonText(tr.status),
                  delay: {show: 300, hide: 200}
                }"
              />
              <label class="text-center">{{ tr.status_formatted.label }}</label>
            </div>
          </vs-td>
          <vs-td width="14%" key="created_at">
            <span v-if="!isEmpty(tr.questionnaire)">{{ $utils.format.date(tr.created_at, false )}}</span>
          </vs-td>
          <vs-td width="18%" v-if="!isEmpty(tr.questionnaire)">
              <feather-icon
                v-permission="'proctoring_config.edit'"
                icon="EditIcon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
                @click="edit(tr)"
                v-tooltip="{
                  content: $t('editar-config'),
                  delay: {show: 400, hide: 500}
                }"
              />
              <feather-icon
                v-permission="'proctoring_config.delete'"
                icon="Trash2Icon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-danger"
                @click="destroy(tr)"
                v-tooltip="{
                  content: $t('excluir-configuracao'),
                  delay: {show: 400, hide: 500}
                }"
              />
              <feather-icon
                v-permission="`proctoring_config.show`"
                icon="VideoIcon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-success"
                @click="open(tr)"
                v-tooltip="{
                  content: $t('abrir-a-monitoracao'),
                  delay: {show: 300, hide: 200}
                }"
              />
              <feather-icon
                icon="LinkIcon"
                svgClasses="h-5 w-5 mb-1 stroke-current text-primary"
                v-clipboard:copy="tr.questionnaire.short_url"
                v-clipboard:success="onSucceddCopy"
                v-clipboard:error="onErrorCopy"
                v-tooltip.top-left="{
                  content: $t('copiar-link-do-questionario'),
                  delay: { show: 1000, hide: 100 }
                }"
              />
            </vs-td>
        </vs-tr>
      </template>
    </Grid>
  </vx-card>
</template>

<script>
import ProctoringService from '@/services/api/ProctoringService'

export default {

  props: {

  },

  data: () => ({
    service: null,
    idToDestroy: null,
    toChange: null,
    routeName: 'proctoring_config'
  }),

  computed: {

  },

  created() {
    this.service = ProctoringService.build(this.$vs)
  },

  methods: {
    recordMedium(recordMedium) {
      switch (recordMedium) {
      case 'L':
        return this.$t('ao-vivo')
      case 'R':
        return this.$t('gravacao')
      case 'LR':
        return this.$t('ao-vivo-gravacao')
      default:
        return '-'
      }
    },
    statusCase(status, obj) {
      switch (status) {
      case 'E':
        return obj.E
      case 'D':
        return obj.D
      default:
        return obj.D
      }
    },
    statusButtonIcon(status) {
      return this.statusCase(status, {E: 'CheckSquareIcon', D: 'SquareIcon'})
    },
    statusButtonText(status) {
      return this.statusCase(status, {E: this.$t('desativar-proctoring'), D: this.$t('ativar-proctoring')})
    },
    toggleToChange() {
      if (this.toChange) {
        // this.$vs.loading()
        this.service.toggleConfig(this.toChange.id).then(
          data => {
            // this.$vs.loading.close()
            this.$refs['grid'].fetchGridData()
          },
          error => {
            // this.$vs.loading.close()
          }
        )
      }
    },
    toggleStatus(config) {
      this.toChange = config
      if (config && config.enabled_similars && config.enabled_similars.length > 0) {
        this.$vs.dialog({
          type: 'confirm',
          color: 'warning',
          title: this.$t('atencao'),
          acceptText: this.$t('sim'),
          cancelText: this.$t('nao'),
          text: this.$tc('proctoring.config.toggle_status_warning', config.enabled_similars.length),
          accept: this.toggleToChange,
        })
      } else {
        this.toggleToChange()
      }
    },
    statusToggleWarningDialog() {

    },
    addNew() {
      this.$router.push('proctoring_config/create')
    },
    dlog(...things) {
      this.devLog('ProctoringConfig', ...things)
    },
    edit(data) {
      this.$router.push(`proctoring_config/${data.id}/edit`)
    },
    destroyModel() {
      this.$vs.loading()
      this.service.deleteConfig(this.idToDestroy).then(
        destroyed => {
          this.$vs.loading.close()
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          this.$refs['grid'].fetchGridData()
        },
        error => {
          this.notifyError(this.$vs, this.$t('nao-foi-possivel-realizar-esta-operacao'))
          this.$vs.loading.close()
        }
      )
      this.$refs['grid'].fetchGridData()
    },
    destroy(data) {
      this.idToDestroy = data.id

      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('confirmacao'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text: this.$t('tem-certeza-que-deseja-excluir-este-registro'),
        accept: this.destroyModel,
      })

    },
    open(data) {
      this.$router.push(`/proctoring_monitor/${data.questionnaire.id}`)
    },
    onSucceddCopy() {
      this.notifySuccess(this.$vs, `${this.$t('copiado')}!`, 2000, 'center-top')
    },
    onErrorCopy() {
      this.notifyError(this.$vs, this.$t('nao-foi-possivel-copiar'))
    },
  }

}
</script>
