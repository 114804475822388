var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vx-card",
    [
      _c("div", { staticClass: "flex flex-wrap-reverse items-center" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "permission",
                rawName: "v-permission",
                value: "proctoring.create",
                expression: "'proctoring.create'",
              },
            ],
            staticClass:
              "p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between font-medium text-base text-primary border border-solid border-primary",
            on: { click: _vm.addNew },
          },
          [
            _c("feather-icon", {
              attrs: { icon: "PlusIcon", svgClasses: "h-4 w-4" },
            }),
            _c("span", { staticClass: "ml-2 text-base text-primary" }, [
              _vm._v(_vm._s(_vm.$t("AddNew"))),
            ]),
          ],
          1
        ),
      ]),
      _c("Grid", {
        ref: "grid",
        attrs: {
          service: _vm.service,
          route_name: _vm.routeName,
          order_column: "updated_at",
          route_grid_path: "/config/grid",
        },
        scopedSlots: _vm._u([
          {
            key: "gridThead",
            fn: function () {
              return [
                _c("vs-th", { key: "id", attrs: { "sort-key": "id" } }, [
                  _vm._v("ID"),
                ]),
                _c(
                  "vs-th",
                  {
                    key: "questionnaire",
                    attrs: { "sort-key": "questionnaire_name" },
                  },
                  [_vm._v(_vm._s(_vm.$t("Questionnaire")))]
                ),
                _c("vs-th", { key: "situation" }, [
                  _vm._v(_vm._s(_vm.$t("stream-de-video"))),
                ]),
                _c("vs-th", { key: "situation" }, [
                  _vm._v(_vm._s(_vm.$t("situacao"))),
                ]),
                _c(
                  "vs-th",
                  { key: "created_at", attrs: { "sort-key": "created_at" } },
                  [_vm._v(_vm._s(_vm.$t("data-de-criacao")))]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "gridActions",
            fn: function () {
              return [
                _c("vs-th", { key: "actions" }, [
                  _vm._v(_vm._s(_vm.$t("Actions"))),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "gridData",
            fn: function (props) {
              return _vm._l(props.data, function (tr, indextr) {
                return _c(
                  "vs-tr",
                  { key: indextr, attrs: { data: tr } },
                  [
                    _c("vs-td", { key: "id", attrs: { width: "6%" } }, [
                      _vm._v(" " + _vm._s(tr.id) + " "),
                    ]),
                    _c(
                      "vs-td",
                      { key: "questionnaire", attrs: { width: "30%" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              !_vm.isEmpty(tr.questionnaire)
                                ? tr.questionnaire.name
                                : "---"
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("vs-td", { attrs: { width: "15%" } }, [
                      _vm._v(
                        " " + _vm._s(_vm.recordMedium(tr.record_medium)) + " "
                      ),
                    ]),
                    _c("vs-td", { key: "situation", attrs: { width: "18%" } }, [
                      !_vm.isEmpty(tr.questionnaire)
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "w-full flex flex-row flex-no-wrap items-center",
                            },
                            [
                              (tr.status === "E") | (tr.status === "D")
                                ? _c("feather-icon", {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: "proctoring_config.toggle",
                                        expression:
                                          "'proctoring_config.toggle'",
                                      },
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: {
                                          content: _vm.statusButtonText(
                                            tr.status
                                          ),
                                          delay: { show: 300, hide: 200 },
                                        },
                                        expression:
                                          "{\n                content: statusButtonText(tr.status),\n                delay: {show: 300, hide: 200}\n              }",
                                      },
                                    ],
                                    staticClass: "py-2",
                                    attrs: {
                                      icon: _vm.statusButtonIcon(tr.status),
                                      svgClasses:
                                        "h-5 w-5 mb-1 mr-3 stroke-current text-success",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleStatus(tr)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _c("label", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(tr.status_formatted.label)),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "vs-td",
                      { key: "created_at", attrs: { width: "14%" } },
                      [
                        !_vm.isEmpty(tr.questionnaire)
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$utils.format.date(tr.created_at, false)
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    !_vm.isEmpty(tr.questionnaire)
                      ? _c(
                          "vs-td",
                          { attrs: { width: "18%" } },
                          [
                            _c("feather-icon", {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: "proctoring_config.edit",
                                  expression: "'proctoring_config.edit'",
                                },
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    content: _vm.$t("editar-config"),
                                    delay: { show: 400, hide: 500 },
                                  },
                                  expression:
                                    "{\n                content: $t('editar-config'),\n                delay: {show: 400, hide: 500}\n              }",
                                },
                              ],
                              attrs: {
                                icon: "EditIcon",
                                svgClasses:
                                  "h-5 w-5 mb-1 mr-3 stroke-current text-warning",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.edit(tr)
                                },
                              },
                            }),
                            _c("feather-icon", {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: "proctoring_config.delete",
                                  expression: "'proctoring_config.delete'",
                                },
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    content: _vm.$t("excluir-configuracao"),
                                    delay: { show: 400, hide: 500 },
                                  },
                                  expression:
                                    "{\n                content: $t('excluir-configuracao'),\n                delay: {show: 400, hide: 500}\n              }",
                                },
                              ],
                              attrs: {
                                icon: "Trash2Icon",
                                svgClasses:
                                  "h-5 w-5 mb-1 mr-3 stroke-current text-danger",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.destroy(tr)
                                },
                              },
                            }),
                            _c("feather-icon", {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: "proctoring_config.show",
                                  expression: "`proctoring_config.show`",
                                },
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    content: _vm.$t("abrir-a-monitoracao"),
                                    delay: { show: 300, hide: 200 },
                                  },
                                  expression:
                                    "{\n                content: $t('abrir-a-monitoracao'),\n                delay: {show: 300, hide: 200}\n              }",
                                },
                              ],
                              attrs: {
                                icon: "VideoIcon",
                                svgClasses:
                                  "h-5 w-5 mb-1 mr-3 stroke-current text-success",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.open(tr)
                                },
                              },
                            }),
                            _c("feather-icon", {
                              directives: [
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:copy",
                                  value: tr.questionnaire.short_url,
                                  expression: "tr.questionnaire.short_url",
                                  arg: "copy",
                                },
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:success",
                                  value: _vm.onSucceddCopy,
                                  expression: "onSucceddCopy",
                                  arg: "success",
                                },
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:error",
                                  value: _vm.onErrorCopy,
                                  expression: "onErrorCopy",
                                  arg: "error",
                                },
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.top-left",
                                  value: {
                                    content: _vm.$t(
                                      "copiar-link-do-questionario"
                                    ),
                                    delay: { show: 1000, hide: 100 },
                                  },
                                  expression:
                                    "{\n                content: $t('copiar-link-do-questionario'),\n                delay: { show: 1000, hide: 100 }\n              }",
                                  modifiers: { "top-left": true },
                                },
                              ],
                              attrs: {
                                icon: "LinkIcon",
                                svgClasses:
                                  "h-5 w-5 mb-1 stroke-current text-primary",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              })
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }